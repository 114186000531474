import { useStaticQuery, graphql } from "gatsby"


export const useCaseStudyData = () => {
  const { wordpress } = useStaticQuery(
    graphql`
      query CaseStudyQuery {
        wordpress {
          page(idType: URI, id: "case-studies") {
            id
            metaContent {
              heroSectionBgColour
              mHeroTitle
            }
          }          
          caseStudies(first: 100) {
            nodes {
              title(format: RENDERED)
              slug
              uri
              featuredImage{
                node {
                  altText
                  srcSet
                  sourceUrl
                  imageFile {
                    publicURL
                    extension
                    childImageSharp {
                      gatsbyImageData(layout: CONSTRAINED)
                      fluid(maxWidth: 1200) {
                        base64
                        aspectRatio
                        src
                        srcSet
                        srcWebp
                        srcSetWebp
                        sizes
                        presentationWidth
                      }            
                      original {
                        src
                      }
                    }
                  }
                }
              }
              metaContent{
                subHeader1
                subHeader2
                loopDescription
              }
            }
          }
        }
      }
    `
  )
  return wordpress
}
