import React from "react"

import styled from "styled-components"

import { useCaseStudyData } from "../hooks/useCaseStudyData";

import ThemeDefault from "../theme/ThemeDefault";

import { Link } from "gatsby";
import LoadedImage from "../helpers/LoadedImage";

import {ParseHTML} from "../helpers/ParseHTML"

const CaseStudySection = styled.section.attrs((props) => ({
  className: props.cssClass,
}))`

  padding: ${ThemeDefault.responsivePadding};
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 64px;

  .inner-div {
    max-width: 1200px;
    margin: 0 auto;
    width: 100% !important;

  }

  .loop-title {
    h2 {
      font-family: ${ThemeDefault.fontSecondary};
      color: ${ThemeDefault.black};
      font-size: ${ThemeDefault.font_3};
    }
  }
  .loop-sub-title {
    color: ${ThemeDefault.black};
    margin-bottom: 16px;
  }

  .no-padding {
    padding: 0;
    width: 100% !important
  }

  .swiper-slide,
  .swiper-container {
    overflow: visible !important;
    height: auto;
  }

  .swiper-slide {
    height: auto !important;
  }
  /* background-color: ${ThemeDefault.offWhite}; */
  .slider-wrapper {
    width: 100%;
    overflow: hidden !important; 
    flex-direction: row !important;
    align-items: stretch !important;

      & .swiper-wrapper {
        flex-direction: row !important;
        align-items: stretch !important;
        ${({ isCentered }) => isCentered && `justify-content: center;`}
      }

  }
  
  
  .spacer {
    padding: ${ThemeDefault.responsivePadding};
    padding-left: 0;
    padding-top: 24px;
    padding-right: 0;
  }

  .arrow-row {
    flex-direction: row;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    text-align: justify;
    flex-grow: 0;
    flex-shrink: 0;
    margin-top: 24px;
    padding: ${ThemeDefault.responsivePadding};
    padding-left: 0;
    padding-top: 24px;
    padding-right: 0;

    svg {
      pointer-events: none;
      padding: 1px;
    }
  }

  .loadedCard {
    & .square-image,
    & .tall-image,
    & .short-image {
      overflow: hidden;
    }

    & .responsive-image {
      left: 50%;
      top: 50%;
      transform: translate( -50%, -50%) scale(1);
      transition: 600ms;
      transition-timing-function: cubic-bezier(.49,.01,.51,1);
    }
    &:hover {
      & .responsive-image {
        transform: translate( -50%, -50%) scale(1.1);
      }
    }

    &.text-inside {
      & .card-title-wrapper {
        background-image: linear-gradient(rgba(22,20,20,0) 0%,rgba(22,20,20,0.92) 50%);
        position: absolute;
        bottom: 0px;
        left: 0px;
        z-index: 99;
        background-blend-mode: multiply;
        width: 100%;
        
        & .card-title {
          padding: 8px;
          padding-top: 24px;
          color: ${ThemeDefault.white};
          font-weight: 500;
          margin: 0;
          line-height: 1.2;
          font-family: ${ThemeDefault.fontPrimary};
          font-size: clamp(1.8rem, calc(1.8rem + ((1vw - 0.48rem) * 0.5)), 2.2rem);

          &.has-sub {
            padding-bottom: 0;
          }
        }

        & .sub-rows {
          padding: 0 8px 8px 8px;
          color: ${ThemeDefault.white};
          font-size: clamp(1rem, calc(1rem + ((1vw - 0.48rem) * 0.3922)), 1.4rem);
          line-height: 1.2;
        }
      }
    }
    &.text-outside {
      & .card-title-wrapper {
        position: relative;
        background: none;

        & .card-title {
          color: ${ThemeDefault.black};
          font-weight: 500;
          margin: 0;
          margin-top: 16px;
          line-height: 1.2;
          font-family: ${ThemeDefault.fontPrimary};
          font-size: clamp(1.6rem, calc(1.6rem + ((1vw - 0.48rem) * 0.1961)), 1.8rem);
        }       
      }
    }

    .loop-description {
      color: ${ThemeDefault.black};
      font-size: clamp(1.2rem, calc(1.2rem + ((1vw - 0.48rem) * 0.2941)), 1.5rem);
      margin-top: 4px;
      line-height: 1.4
    }

    .readMore {
      text-align: left;
      margin-top: 24px;
      font-size: clamp(1.2rem, calc(1.2rem + ((1vw - 0.48rem) * 0.2941)), 1.5rem);
    }
  }
  
  .square-image {
    padding-bottom: 100%;
  }

  .short-image {
    padding-bottom: 65%;
  }

  .slider-card {
    max-width: calc((1200px * 0.66) / 3.4);
    margin-right: 16px;
  }

  .square-image,
  .short-image,
  .tall-image {
    width: 100%;
    position: relative;
    top: 0px;
    left: 0px;
    background: ${ThemeDefault.offWhite};

    & .gatsby-image-wrapper,
    & .gatsby-image-wrapper-constrained,
    & > img {
      position: absolute !important;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover !important;
      object-position: center;
      mix-blend-mode: multiply;

    }
    & .gatsby-image-wrapper-constrained img,
    & .gatsby-image-wrapper img {
      width: 100% !important;
      height: 100% !important;
      object-fit: cover !important;
      left: 50% !important;
      top: 50% !important;
      transform: translate(-50%, -50%) !important;
    }
  }

  .card-wrap {
    display: flex;
    flex-direction: row;
  }

  .loadedCard {

    box-shadow: 0 8px 16px -8px rgba(0, 0, 0, 0.4);
    height: 100%;
    display: flex;
    flex-direction: column;

    & .tall-image,
    & .short-image {
      overflow: hidden;
    }

    & .responsive-image {
      left: 50%;
      top: 50%;
      transform: translate( -50%, -50%) scale(1);
      transition: 600ms;
      transition-timing-function: cubic-bezier(.49,.01,.51,1);
    }
    &:hover {
      & .responsive-image {
        transform: translate( -50%, -50%) scale(1.1);
      }
    }

    &.text-inside {
      & .card-title-wrapper {
        background-image: linear-gradient(rgba(22,20,20,0) 0%,rgba(22,20,20,0.92) 50%);
        position: absolute;
        bottom: 0px;
        left: 0px;
        z-index: 1;
        background-blend-mode: multiply;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        
        & .card-title {
          padding: 8px;
          padding-top: 24px;
          color: ${ThemeDefault.white};
          font-weight: 500;
          margin: 0;
          line-height: 1.2;
          font-family: ${ThemeDefault.fontPrimary};
          font-size: clamp(1.8rem, calc(1.8rem + ((1vw - 0.48rem) * 0.5)), 2.2rem);

          &.has-sub {
            padding-bottom: 0;
          }
        }

        & .sub-rows {
          padding: 0 8px 8px 8px;
          color: ${ThemeDefault.white};
          font-size: clamp(1rem, calc(1rem + ((1vw - 0.48rem) * 0.3922)), 1.4rem);
          line-height: 1.2;
        }
      }
    }
    &.text-outside {
      & .card-title-wrapper {
        position: relative;
        background: none;
        padding: 24px;
        padding-top: 24px;
        background: #fff;
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;

        & .card-title {
          color: ${ThemeDefault.black};
          font-weight: 500;
          margin: 0;
          margin-top: 16px;
          line-height: 1.2;
          font-family: ${ThemeDefault.fontPrimary};
          font-size: clamp(1.6rem, calc(1.6rem + ((1vw - 0.48rem) * 0.1961)), 1.8rem);
        }       
      }
    }

    .loop-description {
      color: ${ThemeDefault.black};
      font-size: clamp(1.2rem, calc(1.2rem + ((1vw - 0.48rem) * 0.2941)), 1.5rem);
      margin-top: 4px;
      line-height: 1.4
    }

    .readMore {
      text-align: left;
      margin-top: 24px;
      font-size: clamp(1.2rem, calc(1.2rem + ((1vw - 0.48rem) * 0.2941)), 1.5rem);
      padding: 8px 16px; 
      border: 1px solid ${ThemeDefault.blue};
      display: inline-block;
    }
  }
  
  .short-image {
    padding-bottom: 75%;
  }

  .card-wrapper {
    display: flex;
    flex-direction: row;
  
    .loadedCardWrapper {
      width: 100%;

      .loadedCard {
        width: 100%;
      }
    }
  }

  
`

const SectionComponent = styled.section.attrs((props) => ({
  className: props.cssClass,
}))`
  
  background-color: ${(props) => props.bgColour};

  .inner-div {
    flex-direction: row;
    display: flex;
    align-items: flex-start;
    max-width: 1200px;
    width: 100% !important;
    margin: 0 auto;  
  }

  h1 {
    font-size: ${ThemeDefault.font_6};
    line-height: 1.4;
    letter-spacing: -1px;
    margin: 32px 0;
    font-weight: 500;
    width: 100%;
    text-align: left;

    p {
      margin-bottom: 20px;
    }
  }

`

const CaseStudies = ( data ) => {
  
  const caseStudyData = useCaseStudyData();

  return (
    <>
      <SectionComponent cssClass="m-top c-center" bgColour={caseStudyData.page.metaContent.heroSectionBgColour} >
        <div className="inner-div wrapper">
          <div className="page-content">
            <h1>{ caseStudyData.page.metaContent.mHeroTitle ? ParseHTML(caseStudyData.page.metaContent.mHeroTitle) : ParseHTML(data.data.title) }</h1>
          </div>
        </div>
      </SectionComponent>
      <CaseStudySection cssClass="c-center" >
        <div className="inner-div no-padding">
          <div className="c-columns-3 c-columns-m-1 c-columns-gap-l">
          {caseStudyData.caseStudies.nodes.map((caseStudy, index) => (
            <CaseStudyCard caseCard={caseStudy} key={`case-${index}`} />
          ))}
          </div>
        </div>
      </CaseStudySection>
    </>
  );
}

export default CaseStudies


const CaseStudyCard = ( props ) => {
  const { caseCard } = props
  
  return (
    <>
      <Link to={caseCard.uri} className="loadedCardWrapper">
        <div className="loadedCard text-outside" >
            <div className={`square-image`}>
              {caseCard.featuredImage && <LoadedImage altText={caseCard.featuredImage.node.altText} imgData={caseCard.featuredImage.node.imageFile} className="responsive-image" />}
            </div>
            <div className="card-title-wrapper">
              <div className="loop-title">
                <h2>{caseCard.metaContent.subHeader1 && ParseHTML(caseCard.metaContent.subHeader1)}</h2>
              </div>
              <div className="loop-sub-title">
                {caseCard.metaContent.subHeader2 && ParseHTML(caseCard.metaContent.subHeader2)}
              </div>
              <div className="description">
                {(caseCard.metaContent && (caseCard.metaContent.loopDescription !== null && caseCard.metaContent.loopDescription !== '')) && 
                  <div className="loop-description">{caseCard.metaContent.loopDescription && ParseHTML(caseCard.metaContent.loopDescription)}</div>
                }  
              </div>
              <div className="blue open">
                <div className="readMore">Read More &rarr;</div>          
              </div>
            </div>
          </div>
        </Link>
    </>
  )
}
