import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

const LoadedImage = ( props ) => {

  // console.log(props);

  const {childImageSharp, publicURL, extension} = props.imgData;

  return (!childImageSharp || extension === 'svg') ? <img src={publicURL} alt={props.altText ? props.altText : ''} className={props.className} /> : <GatsbyImage alt={props.altText ? props.altText : ''} image={childImageSharp.gatsbyImageData} className={props.className} />;
  
}

export default LoadedImage;
