import React from "react"
import Base from "./Base"

import CaseStudies from "../CaseStudy/CaseStudies";

const CaseStudiesTemplate = ({ pageContext }) =>  {
    
  return (
    <Base context={pageContext}>
    {/* {pageContext.isFrontPage ? <ComponentParser key={pageContext} content={pageContext.blocks} /> : <section className="section-bottom"><div className="inner-div"><div className="page-content" dangerouslySetInnerHTML={{ __html: pageContext.content }} /></div></section>} */}
      <CaseStudies data={pageContext} />
    </Base>
  )
}

export default CaseStudiesTemplate